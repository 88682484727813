<template>
  <div class="main-content flex bg-white">
    <div class="content-left flex justify-center flex-col h-full pl-36 pr-12">
      <img src="@/assets/img.png" alt="" width="160">
      <h1>登录Instagram账号</h1>
      <p>使用你的Instagram账号登录本系统</p>
    </div>
    <div class="content-right flex-1 p-14 flex items-center justify-center flex-col">
      <div class="btn" @click="handleAuth">
        <svg width="42" height="42" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             preserveAspectRatio="xMidYMid meet" style="min-width: 42px; align-self: center;">
          <path
              d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12Z"
              fill="#FB2690"></path>
          <path
              d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12Z"
              fill="url(#paint0_linear_InstagramChannel_7619_1727)"></path>
          <path
              d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12Z"
              fill="url(#paint1_radial_7619_1727)"></path>
          <path
              d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12Z"
              fill="url(#paint2_radial_7619_1727)"></path>
          <path
              d="M8.88889 5C7.85749 5 6.86834 5.40972 6.13903 6.13903C5.40972 6.86834 5 7.85749 5 8.88889V15.1111C5 16.1425 5.40972 17.1317 6.13903 17.861C6.86834 18.5903 7.85749 19 8.88889 19H15.1111C16.1425 19 17.1317 18.5903 17.861 17.861C18.5903 17.1317 19 16.1425 19 15.1111V8.88889C19 7.85749 18.5903 6.86834 17.861 6.13903C17.1317 5.40972 16.1425 5 15.1111 5H8.88889ZM16.6667 6.55556C17.0944 6.55556 17.4444 6.90556 17.4444 7.33333C17.4444 7.76111 17.0944 8.11111 16.6667 8.11111C16.2389 8.11111 15.8889 7.76111 15.8889 7.33333C15.8889 6.90556 16.2389 6.55556 16.6667 6.55556ZM12 8.11111C13.0314 8.11111 14.0206 8.52083 14.7499 9.25014C15.4792 9.97945 15.8889 10.9686 15.8889 12C15.8889 13.0314 15.4792 14.0206 14.7499 14.7499C14.0206 15.4792 13.0314 15.8889 12 15.8889C10.9686 15.8889 9.97945 15.4792 9.25014 14.7499C8.52083 14.0206 8.11111 13.0314 8.11111 12C8.11111 10.9686 8.52083 9.97945 9.25014 9.25014C9.97945 8.52083 10.9686 8.11111 12 8.11111ZM12 9.66667C11.6935 9.66677 11.39 9.72724 11.1068 9.84464C10.8237 9.96203 10.5664 10.134 10.3498 10.3509C10.1331 10.5677 9.96125 10.825 9.84404 11.1083C9.72684 11.3915 9.66656 11.695 9.66667 12.0016C9.66677 12.3081 9.72724 12.6116 9.84464 12.8947C9.96203 13.1779 10.134 13.4351 10.3509 13.6518C10.5677 13.8685 10.825 14.0403 11.1083 14.1575C11.3915 14.2747 11.695 14.335 12.0016 14.3349C12.6206 14.3347 13.2142 14.0886 13.6518 13.6507C14.0894 13.2128 14.3351 12.619 14.3349 12C14.3347 11.381 14.0886 10.7873 13.6507 10.3498C13.2128 9.91218 12.619 9.66646 12 9.66667Z"
              fill="white"></path>
          <defs>
            <linearGradient id="paint0_linear_InstagramChannel_7619_1727" x1="7.5" y1="-10" x2="13" y2="15.5"
                            gradientUnits="userSpaceOnUse">
              <stop offset="0.122265" stop-color="#4263DF"></stop>
              <stop offset="1" stop-color="#D53585" stop-opacity="0"></stop>
            </linearGradient>
            <radialGradient id="paint1_radial_7619_1727" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(-6.50955e-07 29.5) rotate(-38.6798) scale(29.7078)">
              <stop stop-color="#FFAD05"></stop>
              <stop offset="1" stop-color="#FF3F00" stop-opacity="0"></stop>
            </radialGradient>
            <radialGradient id="paint2_radial_7619_1727" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(2.5 26) rotate(-37.9987) scale(20.3039)">
              <stop offset="0.01" stop-color="#FDDB86"></stop>
              <stop offset="1" stop-color="#F06942" stop-opacity="0"></stop>
            </radialGradient>
          </defs>
        </svg>
        用 Instagram 账号登录
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  setup() {
    const handleAuth = () => {
      const width = 500
      const height = 600
      const left = (window.screen.width - width) / 2
      const top = (window.screen.height - height) / 2

      const authWindow = window.open(
          'https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1520364905331260&redirect_uri=https://dev.profilingtech.com:9000/api/ins/message/conversation/login&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish',
          'InstagramAuth',
          `width=${width},height=${height},left=${left},top=${top}`
      )

      const timer = setInterval(() => {
        if (authWindow.closed) {
          clearInterval(timer)
          console.log('授权窗口已关闭')
          // 可以在这里检查是否获得授权结果
        }
      }, 500)
    }
    return {
      handleAuth
    }
  }
}
</script>

<style>
.main-content {
  height: 100vh;

  .content-left {
    width: 44%;
    min-width: 350px;
    background-color: #f6f7f9;
    box-sizing: border-box;

    h1 {
      font-size: 40px;
      margin-top: 24px;
      font-weight: 800;
      white-space: break-spaces;
      line-height: 51px;
    }

    p {
      margin-top: 16px;
      color: var(--itsop-font-3);
    }
  }

  .content-right {
    .btn {
      padding: 7px 11px;
      width: 350px;
      margin-bottom: 16px;
      border: solid 1px #e1e5ea;
      border-radius: 6px;
      display: flex;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
<!--https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?client_id=491988066794-u1c7098mcmh971ffcfmncu8dtnqamfvk.apps.googleusercontent.com&redirect_uri=https://app.manychat.com/auth/google?popup=true&scope=https://www.googleapis.com/auth/userinfo.profile-->
